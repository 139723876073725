import React, { useEffect } from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import dataOne from "@components/pageHome/data"
import data from "@components/pagePostulacion/data"
import Intro from "@components/pagePostulacion/components/start"
import MoreJob from "@components/pagePostulacion/components/MoreJob"
import Share from "@components/pagePostulacion/components/share"
import InsertCV from "@components/pagePostulacion/components/insertCV"

const StructureCulqui = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  })


  return (
    <div>
      <BannerCookies />
        
      <Header
        path="/nosotros/postulacion"
        location={location}
        windowsWidth={windowsWidth}
      />
      <section className="container">
        <Intro data={data.intro} location={location} />
        <MoreJob data={data.cards} />
        <div id="puestos" style={{ color: "transparent" }}>fff</div>
        <Share data={data.share} location={location} />
        <InsertCV data={data.insert} />
        <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </section>
    </div>
  )
}

export default StructureCulqui
